import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';

function Verify() {
  const location = useLocation();
  const { phone } = location.state || { phone: '' };
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [verifyStatus, setVerifyStatus] = useState("");
  const navigate = useNavigate();

  const validateOtp = (otp) => {
    const otpPattern = /^[0-9]{6}$/; // Assuming OTP is 6 digits
    return otpPattern.test(otp);
  };

  const verify = async (e) => {
    e.preventDefault();
    if (!validateOtp(otp)) {
      setVerifyStatus("Invalid OTP. Please enter a 6-digit OTP.");
      return;
    }
    setLoading(true);
    try {
      const response = await Axios.post("https://gulshan-vivante.in/admin-panel/api/verify-otp", {
        phone,
        otp,
      });
      if (response.data.message) {
        navigate('/Pending'); // Redirect to Pending page with phone and email
      } else {
        setVerifyStatus("Account creation pending.");
      }
    } catch (error) {
      setVerifyStatus("Invalid OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: "body{background: rgb(63,94,251); background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);}\n .navbar {background-color: #babebf;} .hero-section{height: auto;}" }} />
      <Navbar />
      <div className="container hero-section">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-12 card">
            <div className="container p-3">
              <div className="row">
                <dic className="col-12">
               <span style={{fontSize:'19px'}}> Security Verifaction</span>
                </dic>
                <div className="col-12 mt-2">
                  <p className="text-danger">OTP sent to your phone number. Please verify to complete registration.</p>
                </div>
                <form onSubmit={verify}>
                  <div className="form-group">
                    <label htmlFor="phone"><b>Phone Number</b> <span className="text-danger">*</span></label>
                    <input type="text" name="phone" placeholder="Enter your Phone Number" className="p-2 form-control mt-1" value={phone} disabled />
                  </div>
                  <div className="form-group mb-4 mt-4">
                    <label htmlFor="otp"><b>OTP</b> <span className="text-danger">*</span></label>
                    <input type="text" name="otp" onChange={(e) => setOtp(e.target.value)} className="p-2 form-control mt-1" placeholder="Enter your OTP" required />
                  </div>
                  <div className="form-group mb-4">
                    <button type="submit" className="btn text-white mt-3 w-100 bg-primary p-1" disabled={loading}>
                      {loading ? 'Submitting...' : 'Submit'}
                    </button> 
                  </div>
                  <div className="col-12 mt-4">Already Register ? <Link to="/login" className="text-primary"> <b>Login Now</b></Link></div>
                  {verifyStatus && <h1 style={{ fontSize: '15px', textAlign: 'center', marginTop: '20px',  color:'red' }}>{verifyStatus}</h1>}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Verify;
